@font-face {
  font-family: "MyriadPro";
  src: url("../fonts/Fonts_MyriadPro-Regular.otf");
}

@import "reset";
@import "loading";
@import "slider";
@import "countdown";
@import "video";
