.slider {
  .slide-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 50px;
    transition: ease-in-out all 1s;
    opacity: 0;
    &.active {
      opacity: 1;
      z-index: 1000;
    }
  }
}
