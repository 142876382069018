.slide-item {
  .countdown {
    background-color: #3a3a3a;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out all 1s;

    .countdown-slide {
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 5px;
      padding: 1.2rem 1rem;
      background-size: cover;
      // aspect-ratio: 4 / 4;
      width: 100%;
      height: 100vh;
      background-position: center center;

      .timers {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5vh;
        margin-top: 18vh;

        .timer {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 5vh;
          background-color: #3a3a3a;
          color: #fff;
          width: 10vh;
          height: 10vh;
          padding: 0.7rem;
          border-radius: 100%;
          font-weight: bold;
          // The text representing the timer (Months, Hours, Minutes)
          & span {
            color: #ffe44b;
            position: absolute;
            top: 110%;
            font-weight: normal;
            left: 50%;
            transform: translateX(-50%);
            font-size: 3vh;
          }
        }
      }
      .text {
        color: #ffe44b;
        margin: 5vh auto 0;
        max-width: 100%;
        font-size: 3vh;
      }

      .logo {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          max-height: 40vh;
        }
        .background-image {
          visibility: hidden;
        }
      }
    }
  }
}
